.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #363636;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.viewer {
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.2s, visibility 0.2s;
}
.viewer.hidden {
  visibility: hidden;
  opacity: 0;
}
.viewer *, .viewer *:before, .viewer *:after {
  box-sizing: inherit;
}
.viewer > .viewer-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}
.viewer > .viewer-box {
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.viewer > .viewer-box .viewer-contents {
  position: relative;
  background-color: white;
}
.viewer > .viewer-box .viewer-contents.loading > .loading-spinner {
  visibility: visible;
}
.viewer > .viewer-box .viewer-contents.loading > .img-container {
  opacity: 0 !important;
}
.viewer > .viewer-box .viewer-contents > .loading-spinner {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewer > .viewer-box .viewer-contents > .img-container {
  display: flex;
  width: 600px;
  height: 600px;
  transition: width 0.5s, height 0.5s, opacity 0.2s;
  opacity: 1;
  padding: 5px;
}
.viewer > .viewer-box .viewer-contents > .img-container > img {
  width: 100%;
  height: 100%;
}
.viewer > .viewer-box .viewer-contents > .img-description {
  transition: width 0.5s, height 0.5s;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  display: flex;
}
.viewer > .viewer-box .viewer-contents > .img-description > .close-icon {
  margin-left: auto;
}
.viewer > .viewer-box .viewer-contents > .img-description > .close-icon > a > svg {
  width: 30px;
  height: 30px;
  stroke: #252525;
}
.viewer > .viewer-box .viewer-back, .viewer > .viewer-box .viewer-forward {
  pointer-events: none;
  position: absolute;
  height: 100%;
  display: flex;
  z-index: 200;
  align-items: center;
  width: 200px;
  opacity: 1;
  transition: opacity 0.5s;
}
.viewer > .viewer-box .viewer-back > a, .viewer > .viewer-box .viewer-forward > a {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  pointer-events: auto;
}
.viewer > .viewer-box .viewer-back > a > svg, .viewer > .viewer-box .viewer-forward > a > svg {
  width: 100px;
  height: 100px;
}
.viewer > .viewer-box .viewer-back.hidden, .viewer > .viewer-box .viewer-forward.hidden {
  opacity: 0;
}
.viewer > .viewer-box .viewer-back.hidden > a, .viewer > .viewer-box .viewer-forward.hidden > a {
  pointer-events: none;
}
.viewer > .viewer-box .viewer-back {
  left: -85px;
}
.viewer > .viewer-box .viewer-forward {
  right: -85px;
}
.viewer > .viewer-box .viewer-forward > a > svg {
  margin-left: auto;
}
.viewer > .viewer-box .viewer-back > a > svg > path, .viewer > .viewer-box .viewer-forward > a > svg > path {
  stroke: #b1b1b1;
}